import Link from "next/link";
import { useRouter } from "next/router";

const DarkFooter = () => {
  const router = useRouter()

  return (
    <div className={router.pathname === "/contact_us" ? "dark-footer-container" : ""}>
      <div className="dark-footer-wrapper">
        <div className="row">
          <div className="col-md-3">
            <div className="dark-footer-logo">
              <img alt="Logo Footer" src="/images/logo_white.webp" loading="lazy" />
              <p className="copyright-text">Copyright Southern Health Solutions, Inc.</p>
            </div>
            {/* <div className="footer_phone_new_main desktop-screen">
              <p className="footer_phone_new_p">National Eating Disorder <br />Association Hotline:</p>
              <p className="footer_phone_new_p2">(800) 931-2237</p>
            </div> */}

          </div>
          <div className="col-md-9">
            <div className="row mt-3">
              <div className="col-md-4">
                {" "}
                {/* <p className="dark-p-text">
                  <a href="/contact_us">
                    <div className="label-content position-relative main-change">
                      <div className={`support-msg-way newchange9`}>
                        <div className="ty-icon">
                          <i className="fa fa-phone" aria-hidden="true"></i>
                        </div>
                        <div>
                          <p className="p-val1">Contact Us</p>
                        </div>
                      </div>
                    </div>{" "}
                  </a>
                </p> */}
              </div>
              <div className="col-md-4">
                <div className="dark-social">
                  <a
                    href="https://www.instagram.com/next_medical/"
                    aria-label="Instagram"
                    target="_blank"
                    rel="nofollow"
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/JoinNextMed/"
                    aria-label="Facebook"
                    target="_blank"
                    rel="nofollow"
                  >
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/@joinnextmed"
                    aria-label="Youtube"
                    target="_blank"
                    rel="nofollow"
                  >
                    <i className="fa-brands fa-youtube"></i>
                  </a>
                </div>
              </div>

            </div>
            <hr />
            <div className="row">
              <div className="col-md-3">
                <p className="dark-p-text">Patient tools & resources</p>
                <div className="row">
                  <div className="col-md-4 col-12">
                    <div className="dark-list-wrapper">
                      <ul>
                        <li>
                          <Link href="/login" target="_blank">
                            Login
                          </Link>
                        </li>
                        <li>
                          <Link
                            style={{ cursor: "pointer" }}
                            href="/privacy_policy"
                            target="_blank"
                          >
                            Privacy Policy
                          </Link>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 ">
                <p className="dark-p-text">Contact us </p>
                <div className="row">
                  <div className="col-md-12 col-12">
                    <div className="dark-list-wrapper">
                      <ul>

                        <li>
                          <a href="tel:+18555501960">
                            <div className="label-content position-relative main-change">
                              <div className={`support-msg-way newchange9`}>
                                <div className="ty-icon">
                                  <i
                                    className="fa fa-phone"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div>
                                  <p className="p-val1">+1-855-550-1960 </p>
                                </div>
                              </div>
                            </div>{" "}
                          </a>
                        </li>
                        <li>
                          <a href="mailto:team@joinnextmed.com">
                            <div className="label-content position-relative main-change">
                              <div className={`support-msg-way newchange9`}>
                                <div className="ty-icon">
                                  <i
                                    className="fa fa-envelope-o"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div>
                                  <p className="p-val1">Email US </p>
                                </div>
                              </div>
                            </div>{" "}
                          </a>
                        </li>
                        <li>
                          <div className="label-content position-relative main-change">
                            <div className={`support-msg-way newchange9`}>
                              <div className="ty-icon">
                                <i
                                  className="fa fa-clock-o"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div>
                                <p className="p-val1">24/7, 365</p>
                              </div>
                            </div>
                          </div>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 ">
                <p className="dark-p-text">About medications</p>
                <div className="row">
                  <div className="col-md-12 col-12">
                    <div className="dark-list-wrapper">
                      <ul>
                        <li>
                          <a href='/what-is-ozempic'>
                            <div className="label-content position-relative main-change">
                              <div className={`support-msg-way newchange9`}>
                                <div className="ty-icon">
                                  <i
                                    className="fa fa-info-circle fa-1x"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div>
                                  <small className="p-val1 mx-3">What is Ozempic?</small>
                                </div>
                              </div>
                            </div>{" "}
                          </a>
                        </li>
                        <li>
                          <a href='/what-is-wegovy'>
                            <div className="label-content position-relative main-change">
                              <div className={`support-msg-way newchange9`}>
                                <div className="ty-icon">
                                  <i
                                    className="fa fa-info-circle fa-1x"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div>
                                  <small className="p-val1 mx-3">What is Wegovy?</small>
                                </div>
                              </div>
                            </div>{" "}
                          </a>
                        </li>
                        <li>
                          <a href='/what-is-mounjaro'>
                            <div className="label-content position-relative main-change">
                              <div className={`support-msg-way newchange9`}>
                                <div className="ty-icon">
                                  <i
                                    className="fa fa-info-circle fa-1x"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div>
                                  <small className="p-val1 mx-3">What is Mounjaro?</small>
                                </div>
                              </div>
                            </div>{" "}
                          </a>
                        </li>

                        <li>
                          <a href='/what-is-rybelsus'>
                            <div className="label-content position-relative main-change">
                              <div className={`support-msg-way newchange9`}>
                                <div className="ty-icon">
                                  <i
                                    className="fa fa-info-circle fa-1x"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div>
                                  <small className="p-val1 mx-3">What is Rybelsus?</small>
                                </div>
                              </div>
                            </div>{" "}
                          </a>
                        </li>
                        <li>
                          <a href='/what-is-saxenda'>
                            <div className="label-content position-relative main-change">
                              <div className={`support-msg-way newchange9`}>
                                <div className="ty-icon">
                                  <i
                                    className="fa fa-info-circle fa-1x"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div>
                                  <small className="p-val1 mx-3">What is Saxenda?</small>
                                </div>
                              </div>
                            </div>{" "}
                          </a>
                        </li>
                      </ul>{" "}
                    </div>
                  </div>

                </div>
              </div>

              <div style={{ alignSelf: "center" }} className="col-md-3 col-12 col-file-data">
                <a href="https://legitscript.com" target="_blank" title="Verify LegitScript Approval">
                  <img src="/images/legitscript.webp"
                    alt="LegitScript approved" width="140" height="100" border="0" loading="lazy" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dark-footer-banner-wrapper">
        <span>
          <Link href="HIPAA_notice">HIPAA notice</Link>
        </span>
        <span>
          <Link href="/npp"> Notice of Privacy Practices</Link>
        </span>
        <span>
          <Link href="/membership_agreement"> Membership Agreement</Link>
        </span>
        <span>
          <Link href="/capn">California Privacy Notice</Link>
        </span>
        <span>
          <Link href="terms_of_use">Terms of Services</Link>
        </span>
      </div>
    </div>
  );
};
export default DarkFooter;
